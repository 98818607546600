import { allowedPaths } from "../../middleware";
import { locales } from "i18n";
import { usePathname } from "next/navigation";

export function getLocalizedPath(subpath: string) {
  //@ts-ignore
  const pathname = usePathname();
  const segments = pathname.split("/");

  // Direct match with allowed paths or root
  if (pathname === "/" || allowedPaths.includes(pathname)) {
    return `/${subpath}`;
  }

  // Infer potential language and region from segments
  const potentialLang = segments[1]?.toLowerCase();
  const potentialCountry = segments[2]?.toUpperCase();

  if (locales.includes(`${potentialLang}-${potentialCountry}` as any)) {
    return `/${potentialLang}/${potentialCountry.toLowerCase()}/${subpath}`;
  }

  return `/${subpath}`;
}
