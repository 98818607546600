import axiosInstance from "../axios/axiosInstance";

interface AffiliateResponse {
  owner: string;
  urlSuffix: string;
  split: {
    revShare: number;
  };
  financials: {
    credit: number;
    debit: number;
    pendingPayout: number;
  };
  performance: {
    linkClicks: number;
  };
  _id: string;
  members: any[]; // Specify a more detailed type if possible
  __v: number;
  paxumEmail?: string; // This is optional as it appears in some responses but not all
  paypalEmail?: string; // This is optional as it appears in some responses but not all
  websiteLinks: string[];
}

interface AffiliateURLResponse {
  id: string;
}

interface Invoice {
  _id: string;
  affiliate: string;
  payee: string;
  amount: number;
  status: string;
  generatedAt: string;
  revShare: number;
  transactions: string[];
  createdAt: string;
  updatedAt: string;
  __v: number;
}

interface Info {
  cursor: string | null;
  limit: number;
  total: number;
  unpaid: number;
}

interface AffiliateInvoicesResponse {
  info: Info;
  results: Invoice[];
}

//... other interfaces

export const createAffiliate = async (): Promise<{
  response: AffiliateResponse | null;
  error: any;
}> => {
  try {
    const response = await axiosInstance.post(`/affiliate/`);
    return { response: response.data, error: null };
  } catch (error) {
    console.error("Error creating affiliate:", error);
    return { response: null, error: error };
  }
};

// export const updateAffiliateEmail = async (
//   email: string,
// ): Promise<{ response: AffiliateResponse | null; error: any }> => {
//   try {
//     const response = await axiosInstance.patch(`/affiliate/email`, { email });
//     return { response: response.data, error: null };
//   } catch (error) {
//     console.error("Error updating affiliate email:", error);
//     return { response: null, error: error };
//   }
// };

export const updateAffiliateInfo = async (
  body: any,
): Promise<{ response: AffiliateResponse | null; error: any }> => {
  try {
    const response = await axiosInstance.patch(`/affiliate`, body);
    return { response: response.data, error: null };
  } catch (error) {
    console.error("Error updating affiliate email:", error);
    return { response: null, error: error };
  }
};

export const getAffiliateURL = async (
  urlSuffix: string,
): Promise<{ response: AffiliateURLResponse | null; error: any }> => {
  try {
    const response = await axiosInstance.get(`/affiliate/url/${urlSuffix}`);
    return { response: response.data, error: null };
  } catch (error) {
    console.error("Error getting affiliate URL:", error);
    return { response: null, error: error };
  }
};

export const getAffiliateInfo = async (): Promise<{
  response: AffiliateResponse | null;
  error: any;
}> => {
  try {
    const response = await axiosInstance.get(`/affiliate`);
    // console.log(response)
    return { response: response.data, error: null };
  } catch (error) {
    console.error("Error getting affiliate information:", error);
    return { response: null, error: error };
  }
};

export const getAffiliateCount = async (): Promise<{
  response: { signups: number; activeMembers: number } | null;
  error: any;
}> => {
  try {
    const response = await axiosInstance.get(`/affiliate/count`);
    return { response: response.data, error: null };
  } catch (error) {
    console.error("Error getting affiliate count:", error);
    return { response: null, error: error };
  }
};

export const getAffiliateInvoices = async (): Promise<{
  response: AffiliateInvoicesResponse | null;
  error: any;
}> => {
  try {
    const response = await axiosInstance.get(`/affiliate/invoices`);
    return { response: response.data, error: null };
  } catch (error) {
    console.error("Error getting affiliate invoices:", error);
    return { response: null, error: error };
  }
};

// export const updateAffiliateWebsite = async (
//   websiteUrl: string // New website URL to be updated
// ): Promise<{ response: AffiliateResponse | null; error: any }> => {
//   try {
//     const response = await axiosInstance.patch(`/affiliate/websites`, { website: websiteUrl });
//     return { response: response.data, error: null };
//   } catch (error) {
//     console.error("Error updating affiliate website:", error);
//     return { response: null, error: error };
//   }
// };
