import axios, { InternalAxiosRequestConfig } from "axios";

export interface Config extends InternalAxiosRequestConfig {
  ignoreBaseUrl?: boolean;
}

export const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BACKEND_URL,
});

axiosInstance.interceptors.request.use(
  (config: Config) => {
    config.headers = config.headers || {};
    // Set bearer token for logged in users
    if (typeof window !== "undefined") {
      const token = localStorage.getItem("jwtToken");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }

    // Set origin
    config.headers["x-origin"] = process.env.NEXT_PUBLIC_BASE_URL;

    // Ignore base url
    if (config?.ignoreBaseUrl === false) {
      config.baseURL = "";
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        // Try to remove jwtToken from localStorage
        try {
          if (typeof window !== "undefined") {
            localStorage.removeItem("jwtToken");
          }
        } catch (e) {
          console.error("Something went wrong");
        }
      }
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
