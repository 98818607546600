"use client";
import {
  createContext,
  useContext,
  // Dispatch,
  // SetStateAction,
  useState,
  useEffect,
} from "react";
import { getUser } from "../../lib/api/user";
import { getAffiliateURL } from "@/lib/api/affiliate"; // You should have these functions from the previous response.
import { locales } from "i18n";

interface MembershipProps {
  __v: number;
  _id: string;
  accessToken: string;
  createdAt: string;
  email: string;
  level: number;
  refreshToken: string;
  source: string;
  updatedAt: string;
}

interface CreditProps {
  ccBill: number;
  subStar: number;
  daily: number;
}

export interface UserProps {
  affiliate: any;
  temp: boolean;
  __v: number;
  _id: string;
  email: string;
  createdAt: string;
  googleId: string;
  preferences: any;
  membership: MembershipProps;
  activeMemberships: Map<string, string>;
  credits: CreditProps;
  lastDailyCreditClaim?: Date;
  updatedAt: string;
  username: string;
  banned?: {
    level: number;
    pardonDate: Date;
  };
}

interface ContextProps {
  user: UserProps;
  fetchUser: () => Promise<void>;
  updateCredits: (credits: Partial<CreditProps>) => void;
  updateLastDailyCreditClaim: () => void;
}

const defaultUser = {
  temp: true,
  __v: 0,
  _id: "none",
  createdAt: "",
  googleId: "",
  email: "",
  affiliate: "",
  preferences: {
    privacy: {
      privateProfile: false,
    },
  },
  membership: {
    __v: 0,
    _id: "",
    accessToken: "",
    createdAt: "",
    email: "",
    level: -1,
    refreshToken: "",
    source: "",
    updatedAt: "",
  },
  activeMemberships: new Map<string, string>(),
  credits: {
    ccBill: 0,
    subStar: 0,
    daily: 0,
  },
  lastDailyCreditClaim: undefined,
  updatedAt: "",
  username: "",
};

const GlobalContext = createContext<ContextProps>({
  user: defaultUser,
  fetchUser: async () => {},
  updateCredits: () => {},
  updateLastDailyCreditClaim: () => {},
});

export const GlobalContextProvider = ({ children }: { children: any }) => {
  const [user, setUser] = useState<UserProps>(defaultUser);

  // load user from backend
  const fetchUser = async () => {
    const jwtToken = localStorage.getItem("jwtToken");
    if (jwtToken) {
      const user = await getUser();
      if (user) {
        user.temp = false;
        setUser(user);
      } else {
        let newDefaultUser = JSON.parse(JSON.stringify(defaultUser));
        newDefaultUser.temp = false;
        setUser(newDefaultUser);
      }
    } else {
      let newDefaultUser = JSON.parse(JSON.stringify(defaultUser));
      newDefaultUser.temp = false;
      setUser(newDefaultUser);
    }
  };

  const updateCredits = (creditChanges: Partial<CreditProps>) => {
    setUser((currentUser) => {
      const updatedCredits = {
        ccBill: (currentUser.credits.ccBill || 0) + (creditChanges.ccBill || 0),
        subStar:
          (currentUser.credits.subStar || 0) + (creditChanges.subStar || 0),
        daily: (currentUser.credits.daily || 0) + (creditChanges.daily || 0),
      };

      return {
        ...currentUser,
        credits: updatedCredits,
      };
    });
  };

  const updateLastDailyCreditClaim = () => {
    setUser((currentUser) => {
      return {
        ...currentUser,
        lastDailyCreditClaim: new Date(),
      };
    });
  };

  useEffect(() => {
    const fetchAffiliateId = async (refId: string) => {
      try {
        const result = await getAffiliateURL(refId);
        if (result && result.response && result.response.id) {
          localStorage.setItem("affiliateId", result.response.id);
        }
      } catch (error) {
        console.error("Error in fetchAffiliateId:", error);
      }
    };

    const urlParams = new URLSearchParams(window.location.search);
    const refId = urlParams.get("ref");
    if (refId && refId !== localStorage.getItem("affiliateId")) {
      fetchAffiliateId(refId);
    }

    // set/renew local storage based on current url
    if (typeof window !== "undefined") {
      const pathParts = window.location.pathname.split("/").filter(Boolean); // Remove empty parts
      let currentLocale: (typeof locales)[number] = "en-US"; // Default to English

      if (
        pathParts.length >= 2 &&
        pathParts[0] &&
        pathParts[1] &&
        locales.includes(
          `${
            pathParts[0]
          }-${pathParts[1].toUpperCase()}` as (typeof locales)[number],
        )
      ) {
        currentLocale = `${
          pathParts[0]
        }-${pathParts[1].toUpperCase()}` as (typeof locales)[number];
      } else if (pathParts.length === 1 && pathParts[0]) {
        // This will detect patterns like /de or /fr, assuming they are language-only routes.
        // For such patterns, the country code is duplicated.
        const potentialLocale = `${
          pathParts[0]
        }-${pathParts[0].toUpperCase()}` as (typeof locales)[number];
        if (locales.includes(potentialLocale)) {
          currentLocale = potentialLocale;
        }
      }

      localStorage.setItem("locale", currentLocale);
    }

    fetchUser();
  }, []);

  return (
    <GlobalContext.Provider
      value={{ user, fetchUser, updateCredits, updateLastDailyCreditClaim }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
